import React, { useEffect, useState } from 'react';
import {  } from './Icons';
import Guesses from './Guesses'
import UniqueUnit from '../Interfaces/UniqueUnit';
import '../styles/aoedl.css';
import background from "../images/background.png"

const Aoedl = () => {
  const [guesses, setGuesses] = useState<UniqueUnit[]>([]);

  return (
    <div className={'guessingPage'}>
        <Guesses guesses={guesses} setGuesses={setGuesses}/>
    </div>
  );
};

export default Aoedl;