import { RESOURCE } from './Enums';

export const uniqueUnits = [
    {'name': 'Longbowman', 'range': 5, 'productionTime': 18, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 6, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 0.96, 'hitPoints': 35, 'civilisation': 'Britons'},
    {'name': 'Elite Longbowman', 'range': 6, 'productionTime': 18, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 7, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 0.96, 'hitPoints': 40, 'civilisation': 'Britons'},
    {'name': 'Cataphract', 'range': 1, 'productionTime': 20, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 145, 'attack': 9, 'meleeArmor': 2, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1.35, 'hitPoints': 110, 'civilisation': 'Byzantines'},
    {'name': 'Elite Cataphract', 'range': 1, 'productionTime': 20, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 145, 'attack': 12, 'meleeArmor': 2, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1.35, 'hitPoints': 150, 'civilisation': 'Byzantines'},
    {'name': 'Woad Raider', 'range': 1, 'productionTime': 10, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 11, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1.38, 'hitPoints': 70, 'civilisation': 'Celts'},
    {'name': 'Elite Woad Raider', 'range': 1, 'productionTime': 10, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 14, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1.38, 'hitPoints': 85, 'civilisation': 'Celts'},
    {'name': 'Chu Ko Nu', 'range': 4, 'productionTime': 16, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 0, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 0.96, 'hitPoints': 45, 'civilisation': 'Chinese'},
    {'name': 'Elite Chu Ko Nu', 'range': 4, 'productionTime': 13, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 3, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 0.96, 'hitPoints': 50, 'civilisation': 'Chinese'},
    {'name': 'Throwing Axeman', 'range': 3, 'productionTime': 17, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 7, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 1, 'hitPoints': 60, 'civilisation': 'Franks'},
    {'name': 'Elite Throwing Axeman', 'range': 4, 'productionTime': 17, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 8, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 1, 'hitPoints': 70, 'civilisation': 'Franks'},
    {'name': 'Huskarl', 'range': 1, 'productionTime': 16, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 84, 'attack': 10, 'meleeArmor': 0, 'pierceArmor': 6, 'releaseYear': 1999, 'moveSpeed': 1.05, 'hitPoints': 60, 'civilisation': 'Goths'},
    {'name': 'Elite Huskarl', 'range': 1, 'productionTime': 13, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 78, 'attack': 12, 'meleeArmor': 0, 'pierceArmor': 8, 'releaseYear': 1999, 'moveSpeed': 1.05, 'hitPoints': 70, 'civilisation': 'Goths'},
    {'name': 'Samurai', 'range': 1, 'productionTime': 9, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 10, 'meleeArmor': 1, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1, 'hitPoints': 70, 'civilisation': 'Japanese'},
    {'name': 'Elite Samurai', 'range': 1, 'productionTime': 9, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 12, 'meleeArmor': 1, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1, 'hitPoints': 80, 'civilisation': 'Japanese'},
    {'name': 'Mangudai', 'range': 4, 'productionTime': 26, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 120, 'attack': 6, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 1.4, 'hitPoints': 60, 'civilisation': 'Mongols'},
    {'name': 'Elite Mangudai', 'range': 4, 'productionTime': 26, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 120, 'attack': 8, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 1.4, 'hitPoints': 60, 'civilisation': 'Mongols'},
    {'name': 'War Elephant', 'range': 1, 'productionTime': 25, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 255, 'attack': 15, 'meleeArmor': 1, 'pierceArmor': 2, 'releaseYear': 1999, 'moveSpeed': 0.8, 'hitPoints': 450, 'civilisation': 'Persians'},
    {'name': 'Elite War Elephant', 'range': 1, 'productionTime': 25, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 255, 'attack': 20, 'meleeArmor': 1, 'pierceArmor': 3, 'releaseYear': 1999, 'moveSpeed': 0.8, 'hitPoints': 600, 'civilisation': 'Persians'},
    {'name': 'Mameluke', 'range': 3, 'productionTime': 23, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 140, 'attack': 8, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 1.4, 'hitPoints': 100, 'civilisation': 'Saracens'},
    {'name': 'Elite Mameluke', 'range': 3, 'productionTime': 23, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 140, 'attack': 10, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 1.4, 'hitPoints': 113, 'civilisation': 'Saracens'},
    {'name': 'Teutonic Knight', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 115, 'attack': 14, 'meleeArmor': 7, 'pierceArmor': 2, 'releaseYear': 1999, 'moveSpeed': 0.8, 'hitPoints': 80, 'civilisation': 'Teutons'},
    {'name': 'Elite Teutonic Knight', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 115, 'attack': 17, 'meleeArmor': 10, 'pierceArmor': 2, 'releaseYear': 1999, 'moveSpeed': 0.8, 'hitPoints': 100, 'civilisation': 'Teutons'},
    {'name': 'Janissary', 'range': 7, 'productionTime': 16.8, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 115, 'attack': 17, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 0.96, 'hitPoints': 44, 'civilisation': 'Turks'},
    {'name': 'Elite Janissary', 'range': 8, 'productionTime': 16.8, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 115, 'attack': 22, 'meleeArmor': 2, 'pierceArmor': 0, 'releaseYear': 1999, 'moveSpeed': 0.96, 'hitPoints': 50, 'civilisation': 'Turks'},
    {'name': 'Berserk', 'range': 1, 'productionTime': 14, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 12, 'meleeArmor': 1, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1.05, 'hitPoints': 65, 'civilisation': 'Vikings'},
    {'name': 'Elite Berserk', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 14, 'meleeArmor': 2, 'pierceArmor': 1, 'releaseYear': 1999, 'moveSpeed': 1.05, 'hitPoints': 74, 'civilisation': 'Vikings'},
    {'name': 'Longboat', 'range': 6, 'productionTime': 25, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 150, 'attack': 7, 'meleeArmor': 0, 'pierceArmor': 6, 'releaseYear': 1999, 'moveSpeed': 1.54, 'hitPoints': 130, 'civilisation': 'Vikings'},
    {'name': 'Elite Longboat', 'range': 7, 'productionTime': 25, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 120, 'attack': 8, 'meleeArmor': 0, 'pierceArmor': 8, 'releaseYear': 1999, 'moveSpeed': 1.54, 'hitPoints': 160, 'civilisation': 'Vikings'},
    {'name': 'Jaguar Warrior', 'range': 1, 'productionTime': 10.8, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 10, 'meleeArmor': 1, 'pierceArmor': 2, 'releaseYear': 2000, 'moveSpeed': 1, 'hitPoints': 65, 'civilisation': 'Aztecs'},
    {'name': 'Elite Jaguar Warrior', 'range': 1, 'productionTime': 10.8, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 12, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2000, 'moveSpeed': 1, 'hitPoints': 75, 'civilisation': 'Aztecs'},
    {'name': 'Tarkan', 'range': 1, 'productionTime': 14, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 120, 'attack': 8, 'meleeArmor': 1, 'pierceArmor': 3, 'releaseYear': 2000, 'moveSpeed': 1.4, 'hitPoints': 100, 'civilisation': 'Huns'},
    {'name': 'Elite Tarkan', 'range': 1, 'productionTime': 21.7, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 120, 'attack': 11, 'meleeArmor': 1, 'pierceArmor': 4, 'releaseYear': 2000, 'moveSpeed': 1.4, 'hitPoints': 150, 'civilisation': 'Huns'},
    {'name': 'War Wagon', 'range': 4, 'productionTime': 21, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 160, 'attack': 9, 'meleeArmor': 0, 'pierceArmor': 3, 'releaseYear': 2000, 'moveSpeed': 1.2, 'hitPoints': 150, 'civilisation': 'Koreans'},
    {'name': 'Elite War Wagon', 'range': 5, 'productionTime': 21, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 160, 'attack': 9, 'meleeArmor': 0, 'pierceArmor': 4, 'releaseYear': 2000, 'moveSpeed': 1.2, 'hitPoints': 200, 'civilisation': 'Koreans'},
    {'name': 'Turtle Ship', 'range': 6, 'productionTime': 50, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 332, 'attack': 50, 'meleeArmor': 6, 'pierceArmor': 5, 'releaseYear': 2000, 'moveSpeed': 0.9, 'hitPoints': 200, 'civilisation': 'Koreans'},
    {'name': 'Elite Turtle Ship', 'range': 7, 'productionTime': 50, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 332, 'attack': 50, 'meleeArmor': 8, 'pierceArmor': 6, 'releaseYear': 2000, 'moveSpeed': 1.035, 'hitPoints': 300, 'civilisation': 'Koreans'},
    {'name': 'Plumed Archer', 'range': 4, 'productionTime': 16, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 110, 'attack': 5, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 2000, 'moveSpeed': 1.2, 'hitPoints': 50, 'civilisation': 'Mayans'},
    {'name': 'Elite Plumed Archer', 'range': 5, 'productionTime': 16, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 78, 'attack': 5, 'meleeArmor': 0, 'pierceArmor': 2, 'releaseYear': 2000, 'moveSpeed': 1.2, 'hitPoints': 65, 'civilisation': 'Mayans'},
    {'name': 'Conquistador', 'range': 6, 'productionTime': 24, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 130, 'attack': 16, 'meleeArmor': 2, 'pierceArmor': 1, 'releaseYear': 2000, 'moveSpeed': 1.3, 'hitPoints': 55, 'civilisation': 'Spanish'},
    {'name': 'Elite Conquistador', 'range': 6, 'productionTime': 24, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 130, 'attack': 18, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2000, 'moveSpeed': 1.3, 'hitPoints': 70, 'civilisation': 'Spanish'},
    {'name': 'Kamayuk', 'range': 1, 'productionTime': 10, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 7, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2013, 'moveSpeed': 1, 'hitPoints': 70, 'civilisation': 'Incas'},
    {'name': 'Elite Kamayuk', 'range': 1, 'productionTime': 10, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 8, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2013, 'moveSpeed': 1, 'hitPoints': 80, 'civilisation': 'Incas'},
    {'name': 'Slinger', 'range': 5, 'productionTime': 25, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 4, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2013, 'moveSpeed': 0.96, 'hitPoints': 40, 'civilisation': 'Incas'},
    {'name': 'Elephant Archer', 'range': 4, 'productionTime': 32, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 150, 'attack': 6, 'meleeArmor': 0, 'pierceArmor': 2, 'releaseYear': 2013, 'moveSpeed': 0.9, 'hitPoints': 230, 'civilisation': 'Gurjaras'},
    {'name': 'Imperial Camel Rider', 'range': 1, 'productionTime': 20, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 115, 'attack': 8, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2013, 'moveSpeed': 1.45, 'hitPoints': 140, 'civilisation': 'Hindustanis'},
    {'name': 'Genoese Crossbowman', 'range': 4, 'productionTime': 18, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 85, 'attack': 6, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2013, 'moveSpeed': 0.96, 'hitPoints': 45, 'civilisation': 'Italians'},
    {'name': 'Elite Genoese Crossbowman', 'range': 4, 'productionTime': 14, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 85, 'attack': 6, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2013, 'moveSpeed': 0.96, 'hitPoints': 50, 'civilisation': 'Italians'},
    {'name': 'Condottiero', 'range': 1, 'productionTime': 18, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 85, 'attack': 10, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2013, 'moveSpeed': 1.2, 'hitPoints': 80, 'civilisation': 'Italians'},
    {'name': 'Magyar Huszar', 'range': 1, 'productionTime': 14, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 9, 'meleeArmor': 0, 'pierceArmor': 2, 'releaseYear': 2013, 'moveSpeed': 1.5, 'hitPoints': 75, 'civilisation': 'Magyars'},
    {'name': 'Elite Magyar Huszar', 'range': 1, 'productionTime': 14, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 10, 'meleeArmor': 0, 'pierceArmor': 2, 'releaseYear': 2013, 'moveSpeed': 1.5, 'hitPoints': 85, 'civilisation': 'Magyars'},
    {'name': 'Boyar', 'range': 1, 'productionTime': 15, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 130, 'attack': 12, 'meleeArmor': 4, 'pierceArmor': 2, 'releaseYear': 2013, 'moveSpeed': 1.3, 'hitPoints': 100, 'civilisation': 'Slavs'},
    {'name': 'Elite Boyar', 'range': 1, 'productionTime': 15, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 130, 'attack': 14, 'meleeArmor': 8, 'pierceArmor': 3, 'releaseYear': 2013, 'moveSpeed': 1.3, 'hitPoints': 130, 'civilisation': 'Slavs'},
    {'name': 'Camel Archer', 'range': 4, 'productionTime': 25, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 110, 'attack': 7, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 2015, 'moveSpeed': 1.4, 'hitPoints': 55, 'civilisation': 'Berbers'},
    {'name': 'Elite Camel Archer', 'range': 4, 'productionTime': 25, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 110, 'attack': 8, 'meleeArmor': 1, 'pierceArmor': 1, 'releaseYear': 2015, 'moveSpeed': 1.4, 'hitPoints': 60, 'civilisation': 'Berbers'},
    {'name': 'Genitour', 'range': 4, 'productionTime': 25, 'cost': [RESOURCE.FOOD, RESOURCE.WOOD], 'totalCost': 75, 'attack': 3, 'meleeArmor': 0, 'pierceArmor': 4, 'releaseYear': 2015, 'moveSpeed': 1.35, 'hitPoints': 50, 'civilisation': 'Berbers'},
    {'name': 'Elite Genitour', 'range': 4, 'productionTime': 25, 'cost': [RESOURCE.FOOD, RESOURCE.WOOD], 'totalCost': 75, 'attack': 4, 'meleeArmor': 0, 'pierceArmor': 4, 'releaseYear': 2015, 'moveSpeed': 1.35, 'hitPoints': 55, 'civilisation': 'Berbers'},
    {'name': 'Shotel Warrior', 'range': 1, 'productionTime': 8, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 16, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2015, 'moveSpeed': 1.2, 'hitPoints': 45, 'civilisation': 'Ethiopians'},
    {'name': 'Elite Shotel Warrior', 'range': 1, 'productionTime': 4, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 18, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 2015, 'moveSpeed': 1.2, 'hitPoints': 50, 'civilisation': 'Ethiopians'},
    {'name': 'Gbeto', 'range': 5, 'productionTime': 17, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 10, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2015, 'moveSpeed': 1.25, 'hitPoints': 40, 'civilisation': 'Malians'},
    {'name': 'Elite Gbeto', 'range': 6, 'productionTime': 17, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 13, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2015, 'moveSpeed': 1.25, 'hitPoints': 50, 'civilisation': 'Malians'},
    {'name': 'Organ Gun', 'range': 7, 'productionTime': 21, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 136, 'attack': 6, 'meleeArmor': 2, 'pierceArmor': 4, 'releaseYear': 2015, 'moveSpeed': 0.85, 'hitPoints': 60, 'civilisation': 'Portuguese'},
    {'name': 'Elite Organ Gun', 'range': 7, 'productionTime': 21, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 136, 'attack': 8, 'meleeArmor': 2, 'pierceArmor': 6, 'releaseYear': 2015, 'moveSpeed': 0.85, 'hitPoints': 70, 'civilisation': 'Portuguese'},
    {'name': 'Caravel', 'range': 6, 'productionTime': 36, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 124, 'attack': 6, 'meleeArmor': 0, 'pierceArmor': 8, 'releaseYear': 2015, 'moveSpeed': 1.43, 'hitPoints': 143, 'civilisation': 'Portuguese'},
    {'name': 'Elite Caravel', 'range': 7, 'productionTime': 36, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 124, 'attack': 8, 'meleeArmor': 0, 'pierceArmor': 8, 'releaseYear': 2015, 'moveSpeed': 1.43, 'hitPoints': 165, 'civilisation': 'Portuguese'},
    {'name': 'Arambai', 'range': 5, 'productionTime': 18, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 135, 'attack': 12, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 2016, 'moveSpeed': 1.3, 'hitPoints': 60, 'civilisation': 'Burmese'},
    {'name': 'Elite Arambai', 'range': 5, 'productionTime': 18, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 135, 'attack': 14, 'meleeArmor': 0, 'pierceArmor': 2, 'releaseYear': 2016, 'moveSpeed': 1.3, 'hitPoints': 65, 'civilisation': 'Burmese'},
    {'name': 'Ballista Elephant', 'range': 5, 'productionTime': 25, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 180, 'attack': 10, 'meleeArmor': 0, 'pierceArmor': 3, 'releaseYear': 2016, 'moveSpeed': 0.8, 'hitPoints': 250, 'civilisation': 'Khmer'},
    {'name': 'Elite Ballista Elephant', 'range': 5, 'productionTime': 25, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 180, 'attack': 11, 'meleeArmor': 0, 'pierceArmor': 3, 'releaseYear': 2016, 'moveSpeed': 0.8, 'hitPoints': 290, 'civilisation': 'Khmer'},
    {'name': 'Karambit Warrior', 'range': 1, 'productionTime': 6, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 40, 'attack': 7, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 2016, 'moveSpeed': 1.2, 'hitPoints': 30, 'civilisation': 'Malay'},
    {'name': 'Elite Karambit Warrior', 'range': 1, 'productionTime': 6, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 40, 'attack': 8, 'meleeArmor': 1, 'pierceArmor': 1, 'releaseYear': 2016, 'moveSpeed': 1.2, 'hitPoints': 40, 'civilisation': 'Malay'},
    {'name': 'Rattan Archer', 'range': 4, 'productionTime': 16, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 95, 'attack': 6, 'meleeArmor': 0, 'pierceArmor': 4, 'releaseYear': 2016, 'moveSpeed': 1.1, 'hitPoints': 40, 'civilisation': 'Vietnamese'},
    {'name': 'Elite Rattan Archer', 'range': 5, 'productionTime': 16, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 95, 'attack': 7, 'meleeArmor': 0, 'pierceArmor': 6, 'releaseYear': 2016, 'moveSpeed': 1.1, 'hitPoints': 45, 'civilisation': 'Vietnamese'},
    {'name': 'Imperial Skirmisher', 'range': 5, 'productionTime': 22, 'cost': [RESOURCE.FOOD, RESOURCE.WOOD], 'totalCost': 60, 'attack': 4, 'meleeArmor': 0, 'pierceArmor': 5, 'releaseYear': 2016, 'moveSpeed': 0.96, 'hitPoints': 35, 'civilisation': 'Vietnamese'},
    {'name': 'Konnik', 'range': 1, 'productionTime': 19, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 130, 'attack': 12, 'meleeArmor': 2, 'pierceArmor': 1, 'releaseYear': 2019, 'moveSpeed': 1.35, 'hitPoints': 100, 'civilisation': 'Bulgarians'},
    {'name': 'Elite Konnik', 'range': 1, 'productionTime': 19, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 130, 'attack': 14, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2019, 'moveSpeed': 1.35, 'hitPoints': 120, 'civilisation': 'Bulgarians'},
    {'name': 'Kipchak', 'range': 4, 'productionTime': 20, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 95, 'attack': 0, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2019, 'moveSpeed': 1.4, 'hitPoints': 40, 'civilisation': 'Cumans'},
    {'name': 'Elite Kipchak', 'range': 4, 'productionTime': 20, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 95, 'attack': 2, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2019, 'moveSpeed': 1.621, 'hitPoints': 45, 'civilisation': 'Cumans'},
    {'name': 'Leitis', 'range': 1, 'productionTime': 20, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 120, 'attack': 13, 'meleeArmor': 1, 'pierceArmor': 1, 'releaseYear': 2019, 'moveSpeed': 1.4, 'hitPoints': 100, 'civilisation': 'Lithuanians'},
    {'name': 'Elite Leitis', 'range': 1, 'productionTime': 18, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 120, 'attack': 16, 'meleeArmor': 2, 'pierceArmor': 1, 'releaseYear': 2019, 'moveSpeed': 1.4, 'hitPoints': 130, 'civilisation': 'Lithuanians'},
    {'name': 'Keshik', 'range': 1, 'productionTime': 17, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 100, 'attack': 9, 'meleeArmor': 1, 'pierceArmor': 2, 'releaseYear': 2019, 'moveSpeed': 1.4, 'hitPoints': 110, 'civilisation': 'Tatars'},
    {'name': 'Elite Keshik', 'range': 1, 'productionTime': 15, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 100, 'attack': 11, 'meleeArmor': 1, 'pierceArmor': 3, 'releaseYear': 2019, 'moveSpeed': 1.4, 'hitPoints': 140, 'civilisation': 'Tatars'},
    {'name': 'Flaming Camel', 'range': 1, 'productionTime': 20, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 105, 'attack': 20, 'meleeArmor': 0, 'pierceArmor': 0, 'releaseYear': 2019, 'moveSpeed': 1.3, 'hitPoints': 55, 'civilisation': 'Tatars'},
    {'name': 'Coustillier', 'range': 1, 'productionTime': 15, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 110, 'attack': 8, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2021, 'moveSpeed': 1.35, 'hitPoints': 115, 'civilisation': 'Burgundians'},
    {'name': 'Elite Coustillier', 'range': 1, 'productionTime': 14, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 110, 'attack': 11, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2021, 'moveSpeed': 1.35, 'hitPoints': 145, 'civilisation': 'Burgundians'},
    {'name': 'Flemish Militia(Barracks)', 'range': 1, 'productionTime': 14, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 65, 'attack': 11, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2021, 'moveSpeed': 0.9, 'hitPoints': 60, 'civilisation': 'Burgundians'},
    {'name': 'Serjeant', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 85, 'attack': 5, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2021, 'moveSpeed': 0.9, 'hitPoints': 50, 'civilisation': 'Sicilians'},
    {'name': 'Elite Serjeant', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 85, 'attack': 8, 'meleeArmor': 4, 'pierceArmor': 4, 'releaseYear': 2021, 'moveSpeed': 0.9, 'hitPoints': 85, 'civilisation': 'Sicilians'},
    {'name': 'Hussite Wagon', 'range': 6, 'productionTime': 26, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 180, 'attack': 10, 'meleeArmor': 0, 'pierceArmor': 7, 'releaseYear': 2021, 'moveSpeed': 0.8, 'hitPoints': 160, 'civilisation': 'Bohemians'},
    {'name': 'Elite Hussite Wagon', 'range': 6, 'productionTime': 26, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 180, 'attack': 4, 'meleeArmor': 1, 'pierceArmor': 10, 'releaseYear': 2021, 'moveSpeed': 0.8, 'hitPoints': 230, 'civilisation': 'Bohemians'},
    {'name': 'Houfnice', 'range': 12, 'productionTime': 56, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 450, 'attack': 50, 'meleeArmor': 2, 'pierceArmor': 6, 'releaseYear': 2021, 'moveSpeed': 0.7, 'hitPoints': 90, 'civilisation': 'Bohemians'},
    {'name': 'Winged Hussar', 'range': 1, 'productionTime': 30, 'cost': [RESOURCE.FOOD], 'totalCost': 80, 'attack': 9, 'meleeArmor': 1, 'pierceArmor': 2, 'releaseYear': 2021, 'moveSpeed': 1.5, 'hitPoints': 80, 'civilisation': 'Poles'},
    {'name': 'Obuch', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 8, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2021, 'moveSpeed': 0.9, 'hitPoints': 80, 'civilisation': 'Poles'},
    {'name': 'Elite Obuch', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 10, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2021, 'moveSpeed': 0.9, 'hitPoints': 95, 'civilisation': 'Poles'},
    {'name': 'Urumi Swordsman', 'range': 1, 'productionTime': 9, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 85, 'attack': 8, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2022, 'moveSpeed': 1.05, 'hitPoints': 55, 'civilisation': 'Dravidians'},
    {'name': 'Elite Urumi Swordsman', 'range': 1, 'productionTime': 9, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 85, 'attack': 10, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2022, 'moveSpeed': 1.05, 'hitPoints': 65, 'civilisation': 'Dravidians'},
    {'name': 'Thirisadai', 'range': 6, 'productionTime': 40, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 240, 'attack': 9, 'meleeArmor': 2, 'pierceArmor': 10, 'releaseYear': 2022, 'moveSpeed': 1.3, 'hitPoints': 250, 'civilisation': 'Dravidians'},
    {'name': 'Elite Thirisadai', 'range': 6, 'productionTime': 40, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 240, 'attack': 9, 'meleeArmor': 2, 'pierceArmor': 10, 'releaseYear': 2022, 'moveSpeed': 1.3, 'hitPoints': 250, 'civilisation': 'Dravidians'},
    {'name': 'Chakram Thrower', 'range': 5, 'productionTime': 15, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 95, 'attack': 3, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2022, 'moveSpeed': 1.15, 'hitPoints': 40, 'civilisation': 'Gurjaras'},
    {'name': 'Elite Chakram Thrower', 'range': 6, 'productionTime': 15, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 95, 'attack': 4, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2022, 'moveSpeed': 1.15, 'hitPoints': 50, 'civilisation': 'Gurjaras'},
    {'name': 'Shrivamsha Rider', 'range': 1, 'productionTime': 20, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 100, 'attack': 8, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 2022, 'moveSpeed': 1.6, 'hitPoints': 55, 'civilisation': 'Gurjaras'},
    {'name': 'Elite Shrivamsha Rider', 'range': 1, 'productionTime': 20, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 100, 'attack': 11, 'meleeArmor': 0, 'pierceArmor': 1, 'releaseYear': 2022, 'moveSpeed': 1.6, 'hitPoints': 70, 'civilisation': 'Gurjaras'},
    {'name': 'Ghulam', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 9, 'meleeArmor': 0, 'pierceArmor': 3, 'releaseYear': 2022, 'moveSpeed': 1.15, 'hitPoints': 60, 'civilisation': 'Hindustanis'},
    {'name': 'Elite Ghulam', 'range': 1, 'productionTime': 12, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 75, 'attack': 11, 'meleeArmor': 0, 'pierceArmor': 6, 'releaseYear': 2022, 'moveSpeed': 1.2, 'hitPoints': 70, 'civilisation': 'Hindustanis'},
    {'name': 'Legionary', 'range': 1, 'productionTime': 21, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 12, 'meleeArmor': 2, 'pierceArmor': 2, 'releaseYear': 2023, 'moveSpeed': 0.9, 'hitPoints': 75, 'civilisation': 'Romans'},
    {'name': 'Centurion', 'range': 1, 'productionTime': 24, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 160, 'attack': 13, 'meleeArmor': 2, 'pierceArmor': 3, 'releaseYear': 2023, 'moveSpeed': 1.35, 'hitPoints': 110, 'civilisation': 'Romans'},
    {'name': 'Elite Centurion', 'range': 1, 'productionTime': 24, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 160, 'attack': 15, 'meleeArmor': 3, 'pierceArmor': 3, 'releaseYear': 2023, 'moveSpeed': 1.35, 'hitPoints': 155, 'civilisation': 'Romans'},
    {'name': 'Monaspa', 'range': 1, 'productionTime': 11, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 105, 'attack': 12, 'meleeArmor': 3, 'pierceArmor': 2, 'releaseYear': 2023, 'moveSpeed': 1.4, 'hitPoints': 75, 'civilisation': 'Georgians'},
    {'name': 'Elite Monaspa', 'range': 1, 'productionTime': 11, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 105, 'attack': 14, 'meleeArmor': 5, 'pierceArmor': 2, 'releaseYear': 2023, 'moveSpeed': 1.4, 'hitPoints': 90, 'civilisation': 'Georgians'},
    {'name': 'Composite Bowman', 'range': 4, 'productionTime': 12, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 4, 'meleeArmor': 1, 'pierceArmor': 0, 'releaseYear': 2023, 'moveSpeed': 0.96, 'hitPoints': 40, 'civilisation': 'Armenians'},
    {'name': 'Elite Composite Bowman', 'range': 4, 'productionTime': 10, 'cost': [RESOURCE.WOOD, RESOURCE.GOLD], 'totalCost': 80, 'attack': 4, 'meleeArmor': 2, 'pierceArmor': 0, 'releaseYear': 2023, 'moveSpeed': 0.96, 'hitPoints': 45, 'civilisation': 'Armenians'},
    {'name': 'Warrior Priest', 'range': 1, 'productionTime': 30, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 90, 'attack': 11, 'meleeArmor': 1, 'pierceArmor': 1, 'releaseYear': 2023, 'moveSpeed': 0.85, 'hitPoints': 80, 'civilisation': 'Armenians'},
    {'name': 'Savar', 'range': 1, 'productionTime': 30, 'cost': [RESOURCE.FOOD, RESOURCE.GOLD], 'totalCost': 135, 'attack': 14, 'meleeArmor': 3, 'pierceArmor': 4, 'releaseYear': 2023, 'moveSpeed': 1.35, 'hitPoints': 145, 'civilisation': 'Persians'},
];

export const uniqueUnitProps = {
    name: 'Unique unit',
    range: 'Range',
    productionTime: 'Production time (s)',
    cost: 'Required Resources',
    totalCost: 'Total costs',
    attack: 'Attack',
    meleeArmor: 'Armor',
    pierceArmor: 'Pierce armor',
    releaseYear: 'Release year',
    moveSpeed: 'Move speed',
    hitPoints: 'Hit points',
}