import React, { useEffect, useState } from 'react';
import {  } from './Icons';
import UniqueUnit from '../Interfaces/UniqueUnit';
import '../styles/guessTable.css';
import { uniqueUnitProps } from '../uniqueUnits'
import GuessField from "./guessField";

const GuessTable = (props: {guesses: UniqueUnit[], hiddenUniqueUnit: UniqueUnit, setDoneGuessing: (doneGuessing: boolean) => void}) => {
    const [lastGuessIsCorrect, setLastGuessIsCorrect] = useState({
        name: false,
        range: false,
        productionTime: false,
        cost: false,
        totalCost: false,
        attack: false,
        meleeArmor: false,
        pierceArmor: false,
        releaseYear: false,
        moveSpeed: false,
        hitPoints: false,
    });


    function updateLastGuessIsCorrect(key: string, correctPropertyGuess: boolean) {
        // @ts-ignore
        lastGuessIsCorrect[key] = correctPropertyGuess;
        setLastGuessIsCorrect(lastGuessIsCorrect);
    }

    useEffect(() => {
        console.log(lastGuessIsCorrect)
        if ((Object.values(lastGuessIsCorrect).filter(guessIsCorrect => !guessIsCorrect)).length === 0) {
            props.setDoneGuessing(true);
        }
    }, [lastGuessIsCorrect, props]);

    function getGuessTableStyles() {
        return {gridTemplateColumns: "repeat(" + Object.keys(uniqueUnitProps).length + ", auto)"};
    }

    function getValue(uniqueUnit: UniqueUnit, uniqueUnitProperty: string) {
        // @ts-ignore
        return uniqueUnit[uniqueUnitProperty]
    }

    return (
        <div className={'guessProps'} style={getGuessTableStyles()}>
            {Object.keys(uniqueUnitProps).map((unitProp, index) => {
                // @ts-ignore
                return <div key={index} className={'guessProp'}>{uniqueUnitProps[unitProp]}</div>
            })}
            {props.guesses.map((uniqueUnit, row) => {
                return (Object.keys(uniqueUnitProps).map((uniqueUnitProperty, col) => {
                    // @ts-ignore
                    return <div key={row + '-' + col} className={'guessValue'}>
                        <GuessField
                            value={getValue(uniqueUnit, uniqueUnitProperty)}
                            uniqueUnitProperty={uniqueUnitProperty}
                            hiddenUniqueUnit={props.hiddenUniqueUnit}
                            updateLastGuessIsCorrect={updateLastGuessIsCorrect}
                            isLatestGuess={row === props.guesses.length - 1}
                        />
                    </div>
                }))
            })}
        </div>
    );
};

export default GuessTable;