import React, { useEffect, useState } from 'react';
import {  } from './Icons';
import '../styles/guessField.css';
import meleeArmor from "../images/meleeArmor.png"
import pierceArmor from "../images/pierceArmor.png"
import attack from "../images/attack.png"

import correct from "../images/correct.png"
import higher from "../images/higher.png"
import lower from "../images/lower.png"
import wrong from "../images/wrong.png"
import black from "../images/black.png"


import UniqueUnit from "../Interfaces/UniqueUnit";


const GuessField = (props: {
    value: any,
    uniqueUnitProperty: string,
    hiddenUniqueUnit: UniqueUnit,
    updateLastGuessIsCorrect: (key: string, correctPropertyGuess: boolean) => void
    isLatestGuess: boolean,
}) => {
    function getStatsIcon(stat: string) {
        if (stat === 'meleeArmor') {
            return meleeArmor;
        }
        else if (stat === 'pierceArmor') {
            return pierceArmor;
        }
        else if (stat === 'attack') {
            return attack;
        }
    }

    function getBackgroundImageStyle() {
        if (typeof props.value === 'string') {
            // @ts-ignore
            if (props.value === props.hiddenUniqueUnit[props.uniqueUnitProperty] && props.isLatestGuess) {
                props.updateLastGuessIsCorrect(props.uniqueUnitProperty, true);
            }
            return {backgroundImage: `url(${black})`}
        }
        else if (typeof props.value === 'number') {
            // @ts-ignore
            if (props.value === props.hiddenUniqueUnit[props.uniqueUnitProperty]) {
                if (props.isLatestGuess) {
                    props.updateLastGuessIsCorrect(props.uniqueUnitProperty, true);
                }
                return {backgroundImage: `url(${correct})`}
            }
            // @ts-ignore
            else if (props.value < props.hiddenUniqueUnit[props.uniqueUnitProperty]) {
                return {backgroundImage: `url(${higher})`}
            }
            else {
                return {backgroundImage: `url(${lower})`}
            }
        }
        else if (Array.isArray(props.value)) {
            let isEntirelyDifferent = true;
            let isEqual = true;

            props.value.forEach((value, index) => {
                // @ts-ignore
                if (value === props.hiddenUniqueUnit[props.uniqueUnitProperty][index]) {
                    isEntirelyDifferent = false;
                }
                else {
                    isEqual = false;
                }
            });
            if (isEqual) {
                if (props.isLatestGuess) {
                    props.updateLastGuessIsCorrect(props.uniqueUnitProperty, true);
                }
                return {backgroundImage: `url(${correct})`}
            }
            else if (isEntirelyDifferent) {
                return {backgroundImage: `url(${wrong})`}
            }
            else {
                return {background: `linear-gradient(90deg, #22B14C ${50}%, #FF7F27 ${50}%)`}
            }
        }
    }

    return (
        <div className={'guessField'} style={getBackgroundImageStyle()}>
            {(typeof props.value === 'string' || typeof props.value === 'number') && props.value}
            {typeof props.value === 'object' && !Array.isArray(props.value) &&
                (props.uniqueUnitProperty === 'range'
                    ? <div>
                        {props.value.min === props.value.max
                            ? <div>{props.value.min}</div>
                            : Object.keys(props.value).map((arrayValueProp: any, index) => {
                                return <div key={index}>{arrayValueProp + ': ' + props.value[arrayValueProp]}</div>
                        })}
                    </div>
                    : <div>
                        {Object.keys(props.value).map((arrayValueProp: any, index) => {
                            return <div key={index}><img src={getStatsIcon(arrayValueProp)}/>{props.value[arrayValueProp]}</div>
                        })}
                    </div>)
            }
            {Array.isArray(props.value) &&
                <div>{props.value.map((arrayValue: any, index) => {
                        return <div key={index}>{arrayValue}</div>
                    })}
                </div>
            }
        </div>
    );
};

export default GuessField;