import React, {Ref, useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import {  } from './Icons';
import UniqueUnit from '../Interfaces/UniqueUnit';
import { uniqueUnits } from '../uniqueUnits'
import GuessTable from "./GuessTable";
import '../styles/guesses.css';
// @ts-ignore
import seedrandom from "seedrandom";

const Guesses = (props: {guesses: UniqueUnit[], setGuesses: (guess: UniqueUnit[]) => void}) => {
    const [guessValue, setGuessValue] = useState<any>(undefined);
    const [hiddenUniqueUnit, setHiddenUniqueUnit] = useState<UniqueUnit>(getHiddenUniqueUnit());
    const [doneGuessing, setDoneGuessing] = useState(false);
    const guessRef: Ref<any> = useRef();

    function getHiddenUniqueUnit(): UniqueUnit {
        let now = new Date();
        var rng = seedrandom(now.getUTCDate().toString() + now.getUTCMonth().toString() + now.getUTCFullYear().toString());

        let uniqueUnitsCount = Object.keys(uniqueUnits).length
        let rngNumber = rng();
        // @ts-ignore
        let uu = uniqueUnits[[Math.floor(rngNumber * (uniqueUnitsCount))]];
        return uu;
    }

    function getUniqueUnitOptions(): {value: string, label: string}[] {
        let availableUniqueUnits = uniqueUnits.filter((uniqueUnit: any) => {
          return !props.guesses.map((guess: any) => guess.name).includes(uniqueUnit.name);
        }).map(uniqueUnit => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return {value: uniqueUnit.name, label: uniqueUnit.name + ' (' + uniqueUnit.civilisation + ')'};
    });
        return availableUniqueUnits;
    }

    return (
        <div className={'horizontalCentered'}>
            <div className={'verticalCentered'}>
                <div className={'title'}>AOEDL</div>
                <p className={'title2'}>Guess the Unique Unit</p>
                {!doneGuessing && (
                    <Select
                        ref={guessRef}
                        className={''}
                        placeholder={'Type unique unit or civilisation name...'}
                        options={getUniqueUnitOptions()}
                        onChange={(choice) => {
                            // @ts-ignore
                            let guess: UniqueUnit = uniqueUnits.filter((uniqueUnit: UniqueUnit) => uniqueUnit.name === choice?.value)[0];
                            props.setGuesses([...props.guesses, guess]);
                            setGuessValue('');
                        }}
                        value={guessValue}
                        isSearchable={true}
                        filterOption={(option, inputValue) => {
                            return option.label.toLowerCase().includes(inputValue.toLowerCase()) || inputValue === '';
                        }}
                        openMenuOnFocus={false}
                        openMenuOnClick={false}
                    />
                )}
                {props.guesses.length > 0 && (
                    <GuessTable
                        guesses={props.guesses}
                        hiddenUniqueUnit={hiddenUniqueUnit}
                        setDoneGuessing={setDoneGuessing}
                    />
                )}
                {doneGuessing && (
                    <div>
                        <p className={'success'}>The today's unique unit is the</p>
                        <p className={'successHighlighting'}>{hiddenUniqueUnit.name}</p>
                        <p className={'success'}>and it took you </p>
                        <p className={'successHighlighting'}>{props.guesses.length} tries</p>
                        <p className={'success'}>to find it out. Well done</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Guesses;