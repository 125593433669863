import React from 'react';
import logo from './logo.svg';
import Aoedl from "./components/Aoedl";
import './styles/app.css';
import background from "./images/background.png";

function App() {
  return (
    <div>
        <div className={'background'} style={{backgroundImage: `url(${background})`}}></div>
        <Aoedl />
    </div>
  );
}

export default App;
